var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":"","elevation":"3"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"black--text",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.dataOutbound,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"hide-default-footer":"","dense":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-5",attrs:{"flat":"","dense":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-0 mr-2 d-none"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar-month","label":"Loading Date","readonly":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.loadingDate),callback:function ($$v) {_vm.loadingDate=$$v},expression:"loadingDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.loadingDateMenu),callback:function ($$v) {_vm.loadingDateMenu=$$v},expression:"loadingDateMenu"}},[_c('v-date-picker',{ref:"picker",attrs:{"range":""},on:{"change":_vm.applyFilter},model:{value:(_vm.loadingDate),callback:function ($$v) {_vm.loadingDate=$$v},expression:"loadingDate"}})],1)],1),_c('v-col',{staticClass:"pa-0 mr-2"},[_c('v-select',{attrs:{"label":"Gudang Tujuan","dense":"","outlined":"","hide-details":"","items":_vm.listGudangTujuan,"item-value":"id","item-text":"name"},on:{"change":_vm.getDatas},model:{value:(_vm.tujuanGudang),callback:function ($$v) {_vm.tujuanGudang=$$v},expression:"tujuanGudang"}})],1),_c('v-col',{staticClass:"pa-0 mr-2"},[_c('v-select',{attrs:{"label":"Gudang Asal","dense":"","outlined":"","hide-details":"","items":_vm.listAsalGudang,"item-value":"id","item-text":"name"},on:{"change":_vm.getDatas},model:{value:(_vm.asalGudang),callback:function ($$v) {_vm.asalGudang=$$v},expression:"asalGudang"}})],1),_c('v-col',{staticClass:"pa-0 mr-2"},[_c('v-select',{attrs:{"label":"Detail Transaksi","dense":"","outlined":"","hide-details":"","items":_vm.listDetail,"item-value":"id","item-text":"name"},on:{"change":_vm.getDatas},model:{value:(_vm.detailTransaksi),callback:function ($$v) {_vm.detailTransaksi=$$v},expression:"detailTransaksi"}})],1),_c('v-col',{staticClass:"pa-0 mr-2"},[_c('v-select',{attrs:{"label":"Loading Date","dense":"","outlined":"","hide-details":"","items":_vm.listloadingDate,"item-value":"created_at"},on:{"change":_vm.applyFilter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(
                                                    data.item.created_at ==
                                                        ''
                                                )?_c('span',[_vm._v("all")]):_c('span',[_vm._v(_vm._s(data.item.created_at.substring( 0, 10 )))])]}},{key:"item",fn:function(data){return [(
                                                    data.item.created_at ==
                                                        ''
                                                )?_c('span',[_vm._v("all")]):_c('span',[_vm._v(_vm._s(data.item.created_at.substring( 0, 10 )))])]}}]),model:{value:(_vm.loadingDates),callback:function ($$v) {_vm.loadingDates=$$v},expression:"loadingDates"}})],1),_c('v-col',{staticClass:"pa-0"},[_c('download-csv',{attrs:{"data":_vm.dataJsonToCsv,"name":"Laporan Outbound.csv"}},[_c('v-btn',{staticClass:"mb-2 text-subtitle-1",staticStyle:{"text-transform":"unset !important"},attrs:{"color":"#61B15A","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-download")]),_vm._v(" Unduh Data ")],1)],1)],1)],1)],1),_c('v-toolbar',{staticClass:"mb-5",attrs:{"flat":"","dense":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-magnify","autocomple":"off","placeholder":"Search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"d-flex mb-5 px-4 justify-space-between align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.showPerPage,"outlined":"","dense":"","label":"items per page"},on:{"change":_vm.changeItemPerPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{attrs:{"align":"right"}},[_c('span',{staticClass:"mr-15 text-caption"},[_vm._v("Page: "+_vm._s(_vm.page))]),_c('v-btn',{staticClass:"mx-3",attrs:{"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":_vm.prevPage}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{staticClass:"mx-3",attrs:{"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":_vm.nextPage}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"YYYY/MM/DD"))+" ")]}},{key:"item.manufacturing_date",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.manufacturing_date,"YYYY/MM/DD"))+" ")]}},{key:"item.expired_date",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.expired_date,"YYYY/MM/DD"))+" ")]}},{key:"item.tujuan",fn:function(ref){
                                                var item = ref.item;
return [(item.customer_name !== null)?_c('font',[_vm._v(_vm._s(item.customer_name))]):_c('font',[_vm._v(_vm._s(item.gudang_penerima_name))])]}},{key:"item.mc",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueOfQty(item.mc, "MC", item))+" ")]}},{key:"item.ic",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueOfQty(item.ic, "IC", item))+" ")]}},{key:"item.qr",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValueOfQty(item.qr, "BTL", item))+" ")]}},{key:"item.totBtl",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalAll(item.mc, item.ic, item.qr))+" ")]}}])}),_c('div',{staticClass:"d-flex mt-5 px-4 justify-space-between align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.showPerPage,"outlined":"","dense":"","label":"items per page"},on:{"change":_vm.changeItemPerPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{attrs:{"align":"right"}},[_c('span',{staticClass:"mr-15 text-caption"},[_vm._v("Page: "+_vm._s(_vm.page))]),_c('v-btn',{staticClass:"mx-3",attrs:{"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":_vm.prevPage}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{staticClass:"mx-3",attrs:{"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":_vm.nextPage}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }